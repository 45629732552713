<script lang="ts">
	import CarrierAvatar from "$components/CarrierAvatar.svelte";
	import { Button } from "$lib/components/ui/button";
	import {
		Card,
		CardContent,
		CardFooter,
		CardHeader,
		CardTitle,
	} from "$lib/components/ui/card";
	import super_freight from "$lib/images/super_freight.png";
	import super_woman_freight from "$lib/images/super_woman_freight.png";
	import * as m from "$m";
</script>

<svelte:head>
	<title>Frakty.no</title>
	<meta name="description" content={m.headline()} />
</svelte:head>

<section class="w-full py-12 md:py-24 lg:py-32">
	<div class="container px-4 md:px-6">
		<div
			class="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]"
		>
			<div>
				<img
					alt="Hero"
					class="h-12mx-auto fit object-contain sm:w-full lg:order-last dark:invert"
					src={super_freight}
				/>
			</div>
			<div class="flex flex-col justify-center space-y-4 mt-8 lg:mt-0">
				<div class="space-y-4">
					<h1
						class="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none"
					>
						{m.headline()}
					</h1>
					<p
						class="max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400"
					>
						{m.headline1()}
					</p>
				</div>
				<div class="flex w-full mt-8">
					<Button
						size="lg"
						href="/register"
						class="w-full"
						variant={"outline"}
					>
						{m.get_started()}
					</Button>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="w-full py-12 md:py-24 lg:py-32">
	<div class="container px-4 md:px-6 items-center">
		<h2
			class="text-3xl font-bold tracking-tighter md:text-4xl/tight text-center mb-20"
		>
			{m.send_with()}
		</h2>
		<div class="grid grid-cols-1 sm:grid-cols-3 justify-items-center">
			{#each ["posten", "bring", "postnord"] as carrier}
				<div
					class="basis-1/2 md:basis-1/3 lg:basis-1/4 grayscale hover:grayscale-0 transition-colors duration-200"
				>
					<CarrierAvatar class="h-24 w-24 mx-auto" {carrier} />
				</div>
			{/each}
		</div>
	</div>
</section>

<section class="w-full py-12 md:py-24 lg:py-32 bg-background">
	<div class="container px-4 md:px-6">
		<div
			class="flex flex-col items-center justify-center space-y-4 text-center"
		>
			<div class="space-y-2 my-20">
				<div
					class="inline-block rounded-lg bg-gray-100 px-3 py-1 text-sm dark:bg-gray-800"
				>
					{m.supereasy()}
				</div>
				<h2 class="text-3xl font-bold tracking-tighter sm:text-5xl">
					{m.get_started_fast()}
				</h2>
				<p
					class="max-w-[900px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400"
				>
					{m.get_started_desc()}
				</p>
			</div>
		</div>
		<!-- <div
			class="h-[30vh] md:h-[40vh] lg:h-[50vh] w-full max-w-full rounded-lg overflow-hidden shadow-lg"
		>
			<iframe
				style="width: 100%; height: 100%;"
				src="https://www.youtube.com/embed/0eLGdoE-pVc?si=FRdlwe65VaOQrpeQ"
				title="YouTube video player"
				allow=""
			/>
		</div> -->
	</div>
</section>

<section class="w-full py-12 md:py-24 lg:py-32">
	<div class="container items-center gap-6 px-4 md:px-6 lg:gap-10">
		<h2
			class="text-3xl font-bold tracking-tighter md:text-4xl/tight text-center mb-20"
		>
			{m.price_frakty()}
		</h2>
		<div class="grid grid-cols-1 md:grid-cols-2 gap-8 md:mx-10">
			<Card>
				<CardHeader>
					<CardTitle>
						<h3
							class="text-3xl font-bold tracking-tighter md:text-4xl/tight text-center"
						>
							Gratis
						</h3>
					</CardTitle>
				</CardHeader>
				<CardContent class="gap-4">
					<p class="text-gray-500 my-4 min-h-16 md:min-h-32">
						{m.package_free_desc()}
					</p>
				</CardContent>
				<CardFooter>
					<Button href="/register" class="w-full" variant="outline"
						>{m.get_started()}</Button
					>
				</CardFooter>
			</Card>

			<Card>
				<CardHeader>
					<h3
						class="text-3xl font-bold tracking-tighter md:text-4xl/tight text-center"
					>
						Eksterne avtaler
					</h3>
				</CardHeader>
				<CardContent class="gap-4">
					<p class="text-gray-500 my-4 min-h-16 md:min-h-32">
						{m.package_plus_desc()}
					</p>
					<div class="text-center">
						<span class="font-bold">199 kr {m.month()}</span>
					</div>
				</CardContent>
				<CardFooter>
					<Button href="/register" class="w-full" variant="outline"
						>{m.get_started()}</Button
					>
				</CardFooter>
			</Card>
		</div>
		<div class="text-center text-gray-500 mt-10 space-y-4">
			<p>
				{m.packages_subtitle()}
			</p>
			<Button href="mailto:robin@frakty.no">Send epost</Button>
		</div>
	</div>
</section>

<section class="w-full py-12 md:py-24 lg:py-32">
	<div class="container items-center gap-6 px-4 md:px-6 lg:gap-10">
		<h2
			class="text-3xl font-bold tracking-tighter md:text-4xl/tight text-center mb-20"
		>
			{m.price_for_delivery()}
		</h2>
		<div class="grid grid-cols-1 md:grid-cols-2 gap-8 md:mx-10"></div>
	</div>
	<div
		class="grid md:mx-8 grid-cols-2 space-x-4 gap-6 px-4 md:px-6 lg:gap-10 items-center place-content-center justify-center"
	>
		<div class="max-w-96 place-self-end">
			<p class="text-lg text-gray-600 dark:text-gray-200 mb-4">
				Leveringskostnaden avhenger av sendingsruten. Med
				Profrakt-avtale gjennom Frakty nyter du allerede godt av
				stordriftsfordeler og attraktive rabatter.
			</p>

			<p class="text-lg text-gray-600 dark:text-gray-200">
				Når du oppretter et fraktoppdrag, får du umiddelbart estimerte
				priser for alle produkter, slik at du enkelt kan velge det
				alternativet som passer deg best.
			</p>
		</div>
		<div class="space-y-2 max-w-96">
			<Card class="grid grid-cols-12 p-2 items-center">
				<span class="tracking-tight text-sm col-span-6">
					Pakke i Postkasssen
				</span>
				<div class="col-span-3">
					<CarrierAvatar carrier="bring" />
				</div>
				<span class="font-bold text-sm col-span-3"
					>{(49).toLocaleString("nb-NO", {
						style: "currency",
						currency: "NOK",
					})}</span
				>
			</Card>
			<Card class="grid grid-cols-12 p-2 items-center">
				<span class="tracking-tight text-sm col-span-6">
					Pakke til hentested
				</span>
				<div class="col-span-3">
					<CarrierAvatar carrier="bring" />
				</div>
				<span class="font-bold text-sm col-span-3"
					>{(69).toLocaleString("nb-NO", {
						style: "currency",
						currency: "NOK",
					})}</span
				>
			</Card>
			<Card class="grid grid-cols-12 p-2 items-center">
				<span class="tracking-tight text-sm col-span-6">
					Mypack Collect
				</span>
				<div class="col-span-3">
					<CarrierAvatar carrier="postnord" />
				</div>
				<span class="font-bold text-sm col-span-3"
					>{(89).toLocaleString("nb-NO", {
						style: "currency",
						currency: "NOK",
					})}</span
				>
			</Card>
			<p class="text-gray-500 text-center text-xs pt-4">
				Eksempel priser
			</p>
		</div>
	</div>
</section>

<section class="w-full py-12 md:py-24 lg:py-32 border-t">
	<div class="container px-4 md:px-6">
		<div class="grid gap-10 sm:px-10 md:gap-16 md:grid-cols-2">
			<div class="space-y-4 self-center">
				<div class="space-y-2">
					<div
						class="inline-block rounded-lg bg-gray-100 px-3 py-1 text-sm dark:bg-gray-800"
					>
						{m.supereasy()}
					</div>
					<p class="text-lg text-gray-600 dark:text-gray-200">
						{m.fiken_easy_desc()}
					</p>
				</div>
				<div class="space-y-4">
					<div
						class="inline-block rounded-lg bg-gray-100 px-3 py-1 text-sm dark:bg-gray-800"
					>
						{m.Print()}
					</div>
					<p class="text-lg text-gray-600 dark:text-gray-200">
						{m.profrakt_label_easy()}
					</p>
				</div>
			</div>
			<div class="m-12 sm:m-16 lg:m-20">
				<img
					alt="Hero"
					class="mx-auto fit object-contain sm:w-full lg:order-last"
					src={super_woman_freight}
				/>
			</div>
		</div>
	</div>
</section>

<section class="w-full py-12 md:py-24 lg:py-32 border-t">
	<div
		class="container grid items-center justify-center gap-4 px-4 text-center md:px-6"
	>
		<div class="space-y-3">
			<h2 class="text-3xl font-bold tracking-tighter md:text-4xl/tight">
				{m.do_you_need_to_send_something()}
			</h2>
			<p
				class="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400"
			>
				{m.you_can_use_it_for_free()}
			</p>
		</div>
		<div class="mx-auto w-full max-w-sm space-y-2">
			<Button href="/register">
				{m.get_started()}
			</Button>
		</div>
	</div>
</section>
